var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"app-share-edit"},[_c('a-page-header',{attrs:{"title":"分享有礼","sub-title":"分享者和助力者都可以领取优惠券"}}),_c('div',{staticClass:"adv-form"},[_c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.horizonLayout,false),[_c('a-form-model-item',{attrs:{"required":"","label":"活动名称","prop":"title","rules":[
            {
              required: true,
              message: '活动名称必填'
            }
          ]}},[_c('a-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('a-form-model-item',{attrs:{"required":"","label":"开始时间","prop":"startTime","rules":{
            required: true,
            message: '开始时间必填'
          }}},[_c('a-date-picker',{attrs:{"valueFormat":"YYYY-MM-DD HH:mm","format":"YYYY-MM-DD HH:mm","disabled-date":_vm.disabledStartDate,"show-time":{ format: 'h:mm' }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('a-form-model-item',{attrs:{"required":"","label":"结束时间","prop":"endTime","rules":{
            required: true,
            message: '结束时间必填'
          }}},[_c('a-date-picker',{attrs:{"valueFormat":"YYYY-MM-DD HH:mm","format":"YYYY-MM-DD HH:mm","disabled-date":_vm.disabledEndDate,"show-time":{ format: 'h:mm' }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1),_c('a-form-model-item',{attrs:{"required":"","label":"助力人数","prop":"num","rules":[
            {
              required: true,
              message: '助力人数必填'
            }
          ]}},[_c('a-input-number',{attrs:{"min":2,"max":100},model:{value:(_vm.form.num),callback:function ($$v) {_vm.$set(_vm.form, "num", $$v)},expression:"form.num"}})],1),_c('a-form-model-item',{attrs:{"required":"","label":"分享者优惠券","help":"请确保优惠券库存充足","prop":"couponId","rules":[
            {
              required: true,
              message: '优惠券必填'
            }
          ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.couponId),callback:function ($$v) {_vm.$set(_vm.form, "couponId", $$v)},expression:"form.couponId"}},_vm._l((_vm.couponList),function(c){return _c('a-select-option',{key:c.id,attrs:{"value":c.id}},[_vm._v(_vm._s(c.name)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"required":"","label":"助力者优惠券","help":"请确保优惠券库存充足，例如：预计参加活动人数100，要求3个助力，优惠券库存至少需要300张。","prop":"coupon2Id","rules":[
            {
              required: true,
              message: '优惠券必填'
            }
          ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.coupon2Id),callback:function ($$v) {_vm.$set(_vm.form, "coupon2Id", $$v)},expression:"form.coupon2Id"}},_vm._l((_vm.couponList),function(c){return _c('a-select-option',{key:c.id,attrs:{"value":c.id}},[_vm._v(_vm._s(c.name)+" ")])}),1)],1),_c('a-form-model-item',{staticStyle:{"margin-top":"50px"},attrs:{"required":"","label":"分享标题","prop":"shareTitle","rules":[
            {
              required: true,
              message: '分享标题必填'
            }
          ]}},[_c('a-input',{model:{value:(_vm.form.shareTitle),callback:function ($$v) {_vm.$set(_vm.form, "shareTitle", $$v)},expression:"form.shareTitle"}})],1),_c('a-form-item',{attrs:{"label":"分享封面","help":"建议长宽比：5:4，例如：600 x 480","required":"","prop":"shareImg","rules":[
            {
              required: true,
              message: '分享封面必填'
            }
          ]}},[_c('a-upload',{attrs:{"data":{ index: 0 },"listType":"picture-card","action":_vm.UPLOAD_URL,"headers":{ t: _vm.getToken },"withCredentials":"","accept":"image/*","showUploadList":false,"before-upload":_vm.beforeUpload},on:{"change":_vm.handleUpload}},[(_vm.form.shareImg)?_c('img',{staticStyle:{"width":"80px"},attrs:{"src":_vm.form.shareImg}}):_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传 ")])],1)])],1),_c('a-form-model-item',{attrs:{"wrapper-col":{ span: 18, offset: 6 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.submitForm}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }